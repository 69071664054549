<!-- 绑定设备页面，头部有搜索框，可以搜索设备编号，中间有一个扫描二维码按钮，点击按钮可以扫描获取设备编号再搜索 -->
<template>
  <div class="add-devices">
    <nut-searchbar v-model='searchValue' @search="bind" @click-right-icon="bind">
      <template v-slot:leftin>
        <Search2 />
      </template>
      <template v-slot:rightout>
        <div @click="bind">绑定</div>
      </template>
    </nut-searchbar>
  </div>
  <!-- 扫描二维码 -->
  <div class="add-devices-scan">
    <nut-button type="primary" @click="scan">扫描二维码绑定</nut-button>
  </div>
  <video ref="video"></video>
  <!-- 已绑定设备，用卡片形式展示 -->
  <div class="add-devices-card">
    <!-- 遍历machineList -->
    <nut-card v-for="(item, index) in machineList" :key="index" :img-url="item.imgpath" :title="item.name" :shop-desc="item.type" :delivery="item.machineid" :shop-name="item.location" :is-need-price="false">
      <template #footer>
      <div class="customize" @click="unband(item.machineid)">解绑设备</div>
    </template>
    </nut-card>
  </div>
</template>

<script>
import { Search2 } from '@nutui/icons-vue';
import { showToast,showDialog } from '@nutui/nutui';
import jsQR from 'jsqr'

export default {
  name: 'AddDevices',
  components: {
    Search2
  },
  mounted() {
    // 获取sessionStorage中的id
    this.uid = sessionStorage.getItem('id')
    console.log(this.uid);
    this.getMachine()
  },
  data() {
    return {
      uid: '',
      searchValue: '',
      machineList: [
        {
          "imgpath": "string",
          "location": "广东省测试地址",
          "machineid": "20132165",
          "name": "测试烟感",
          "type": "警备中"
        }
      ],
      // addmachine表单
      addmachineForm: {
        machineID: '',
        name: '通知信息',
        imgpath: 'https://img.starlake.tech/pic/d4a2083699b8f0276cdb7d173a607572.png',
        location: '广东省肇庆市',
        type: '监控中',
      },
    };
  },
  methods: {
    // 获取设备列表
    getMachine() {
      this.axios.get('/machine/my/').then((res) => {
        console.log(res)
        this.machineList = res.data
      })
    },
    // 添加设备到后台并绑定
    bind() {
      this.addmachineForm.machineID = this.searchValue
      console.log(1);
      // 调用
      this.bindDevices()
    },
    // 解绑设备，先提示警告，再调用
    unband(machineid) {
      console.log(machineid)
      // 提示
      showDialog({
          title: '确定解绑该设备吗？',
          onOk: () => {
            this.onOk(machineid)
          },
        });
    },
    onOk(machineid) {
      console.log(1);
      // 调用
      this.unbandDevices(machineid)
    },
    // 解绑设备
    unbandDevices(machineid) {
      this.axios.post('/machine/unbind/', {
        machineID: machineid, uid: parseInt(this.uid)
      }).then((res) => {
        console.log(res);
        // 解绑成功，提示解绑成功
        showToast.success('解绑成功')
        // 刷新页面
        this.getMachine()
      }).catch((err) => {
        console.log(err);
        // 解绑失败，提示解绑失败
        showToast.error('解绑失败')
      })
    },
    // 绑定设备
    bindDevices() {
      // 先添加设备到后台，再绑定
      // 添加设备
      this.axios.post('/machine/', this.addmachineForm).then((res) => {
        console.log(res);
        // 添加成功，绑定设备
        this.axios.post('/machine/bind/', {
          machineID: this.searchValue, uid: parseInt(this.uid)
        }).then((res) => {
          console.log(res);
          // 绑定成功，提示绑定成功
          showToast.success('绑定成功')
          // 刷新页面
          this.getMachine()
        }).catch((err) => {
          console.log(err);
          // 绑定失败，提示绑定失败
          showToast.error('绑定失败')
        })
      }).catch((err) => {
        console.log(err);
        // 添加失败，提示添加失败
        showToast.error('添加失败')
      })
    },
    // 扫描二维码
    scan() {
      // 调用     
      this.scanQRCode()
    },
    scanQRCode() {
      navigator.mediaDevices.getUserMedia({ video: { facingMode: { exact: "environment" }, }, }).then((stream) => {
        this.$refs.video.srcObject = stream
        this.$refs.video.play()
      }).catch((err) => {
        console.log(err);
        // 获取后置摄像头失败,切换到前置摄像头
        navigator.mediaDevices.getUserMedia({ video: 'true' }).then((stream) => {
          this.$refs.video.srcObject = stream
          this.$refs.video.play()
        }).catch((err) => {
          // 获取前置摄像头失败
          console.log(err)
        })
      })
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const video = this.$refs.video

      const scan = () => {
        if (video.readyState === video.HAVE_ENOUGH_DATA) {
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
          const code = jsQR(imageData.data, imageData.width, imageData.height)

          if (code) {
            console.log(code.data)
            // 关闭摄像头
            video.srcObject.getTracks().forEach(track => track.stop())
            // 清空canvas
            ctx.clearRect(0, 0, canvas.width, canvas.height)
            // 停止video占用
            video.srcObject = null
            // 扫描成功，提示扫出的号码，确认后search
            showToast.success('扫描成功，号码为：' + code.data)
            this.searchValue = code.data
            this.addmachineForm.machineID = code.data
          }

          requestAnimationFrame(scan)
        } else {
          setTimeout(() => {
            requestAnimationFrame(scan)
          }, 100)
        }
      }

      scan()
    }
  }
};
</script>

<style scoped>
.add-devices {
  margin-top: 20px;
}

.add-devices-scan {
  margin-top: 20px;
}

.add-devices-card {
  margin-top: 20px;
}

.customize{
  font-size: 12px;
  padding-top: 40px;
}
</style>

